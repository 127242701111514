import React, { useState, useEffect } from 'react';
import logo from "../../assets/logos/logo.png";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';

const Navbar = ({ isLoggedIn, setIsLoggedIn }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (location.pathname === '/admindashboard') {
        return null; // Do not render the navbar on admin dashboard
    }

    // Logout function
    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        setIsLoggedIn(false); 
        navigate('/'); 
    };

    // Show Login Modal
    const handleLoginClick = () => {
        setShowLoginModal(true);
    };

    // Show Register Modal
    const handleRegisterClick = () => {
        setShowRegisterModal(true);
    };

    // Handle closing the modals
    const handleCloseModal = () => {
        setShowLoginModal(false);
        setShowRegisterModal(false);
    };

    // Handle successful login (to update the state globally in App.js)
    const handleLoginSuccess = () => {
        setIsLoggedIn(true); // Update isLoggedIn state in App.js
        setShowLoginModal(false); // Close the modal after successful login
    };

    const handleProfileClick = () => {
        navigate('/profile');
    };

    return (
        <div className={`sticky top-0 z-50 transition-shadow duration-300 bg-[#E3DCD7] ${isScrolled ? 'shadow-lg bg-white' : ''}`}>
            <div className='container mx-auto'>
                <div className='flex justify-between p-5 items-center'>
                    <NavLink to="/" >
                        <img src={logo} alt='logo' className='h-[50px]' />
                    </NavLink>
                    <div className='hidden md:flex gap-5'>
                        <NavLink to="/" exact className={({ isActive }) => isActive ? 'text-[#AB3430]' : 'text-gray-700'}>Home</NavLink>
                        <NavLink to="/category" className={({ isActive }) => isActive ? 'text-[#AB3430]' : 'text-gray-700'}>Category</NavLink>
                        <NavLink to="/gallery" className={({ isActive }) => isActive ? 'text-[#AB3430]' : 'text-gray-700'}>Gallery</NavLink>
                        <NavLink to="/about" className={({ isActive }) => isActive ? 'text-[#AB3430]' : 'text-gray-700'}>About</NavLink>
                    </div>
                    <div className='flex gap-5 items-center'>
                        <div className='hidden md:block'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="gray" className="size-6 hover:text-[#ab3430]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                            </svg>
                        </div>

                        {isLoggedIn ? (
                            <div className='hidden md:block'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 hover:text-[#ab3430] text-gray-500" onClick={handleProfileClick}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                </svg>
                            </div>
                        ) : (
                            <>
                                <button className="hidden md:block text-gray-700 hover:text-[#AB3430]" onClick={handleLoginClick}>Login</button>
                                <button className="hidden md:block text-gray-700 hover:text-[#AB3430]" onClick={handleRegisterClick}>Register</button>
                            </>
                        )}

                        {/* Cart Icon */}
                        <div className='hidden md:block'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="gray" className="size-6 hover:text-[#ab3430]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                            </svg>
                        </div>

                        <button className='md:hidden' onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            <div className={`md:hidden fixed top-0 right-0 w-64 h-full bg-[#E3DCD7] shadow-lg p-4 transition-transform transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} ease-in-out duration-300 z-50`}>
                <button className='absolute top-4 right-4' onClick={() => setIsMenuOpen(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6m0 12L6 6" />
                    </svg>
                </button>
                <nav>
                    <ul className="space-y-4">
                        <li><NavLink to="/" className="text-gray-700 hover:text-[#AB3430]" onClick={() => setIsMenuOpen(false)}>Home</NavLink></li>
                        <li><NavLink to="/category" className="text-gray-700 hover:text-[#AB3430]" onClick={() => setIsMenuOpen(false)}>Category</NavLink></li>
                        <li><NavLink to="/gallery" className="text-gray-700 hover:text-[#AB3430]" onClick={() => setIsMenuOpen(false)}>Gallery</NavLink></li>
                        <li><NavLink to="/about" className="text-gray-700 hover:text-[#AB3430]" onClick={() => setIsMenuOpen(false)}>About</NavLink></li>
                        <li>
                            {isLoggedIn ? (
                                <button className="text-gray-700 hover:text-[#AB3430]" onClick={() => { handleProfileClick(); setIsMenuOpen(false); }}>Profile</button>
                            ) : (
                                <>
                                    <button className="text-gray-700 hover:text-[#AB3430]" onClick={() => { setShowLoginModal(true); setIsMenuOpen(false); }}>Login</button>
                                    <button className="text-gray-700 hover:text-[#AB3430]" onClick={() => { setShowRegisterModal(true); setIsMenuOpen(false); }}>Register</button>
                                </>
                            )}
                        </li>
                    </ul>
                </nav>
            </div>

            {/* Modals */}
            {/* Login Modal */}
            <LoginModal 
                isVisible={showLoginModal} 
                onClose={handleCloseModal} 
                onLoginSuccess={handleLoginSuccess}
            />

            {/* Register Modal */}
            <RegisterModal 
                isVisible={showRegisterModal} 
                onClose={handleCloseModal}
            />
        </div>
    );
};

export default Navbar;
