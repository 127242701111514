import React, { useState } from 'react';
import { loginApi } from '../../Apis/Api'; // Import the login API function
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/logos/logo.png";
import RegisterModal from './RegisterModal';
import toast from 'react-hot-toast'; // Import toast from react-hot-toast

const LoginModal = ({ isVisible, onClose, onLoginSuccess }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [emailError, setEmailError] = useState(''); // State for email error message
    const [passwordError, setPasswordError] = useState(''); // State for password error message

    const handleRegisterClick = () => {
        setShowRegisterModal(true);
    };
    const handleCloseModal = () => {
        setShowRegisterModal(false);
    };

    if (!isVisible) return null;

    // Function to handle form submission
    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null); // Clear previous error messages
        setEmailError(''); // Clear email error
        setPasswordError(''); // Clear password error

        // Validation
        if (!email) {
            setEmailError('Email is required');
            toast.error('Please enter your email'); // Toast notification
            return;
        }
        if (!password) {
            setPasswordError('Password is required');
            toast.error('Please enter your password'); // Toast notification
            return;
        }

        try {
            const response = await loginApi({ email, password }); // Call the API with login data
            if (response.data.success) {
                const { token, user } = response.data;
                
                localStorage.setItem("token", token);
                localStorage.setItem("user", JSON.stringify(user)); // Save user details

                // Check if the logged-in user is an admin
                if (user.isAdmin) {
                    navigate("/admindashboard"); // Redirect to admin dashboard if user is an admin
                } else {
                    navigate("/"); // Redirect to user dashboard (profile) if not an admin
                }

                onLoginSuccess(); // Notify parent that login was successful
                onClose(); // Close the modal on successful login
                toast.success('Login successful!'); // Success toast notification
            } else {
                setError(response.data.message); // Handle failed login
                toast.error(response.data.message); // Error toast notification
            }
        } catch (error) {
            setError('Login failed. Please try again.'); // Handle any other errors
            toast.error('Login failed. Please try again.'); // Error toast notification
        }
    };

    // Function to handle outside modal click
    const handleOutsideClick = (e) => {
        if (e.target.id === 'modal-container') {
            onClose();
        }
    };

    return (
        <div 
            id="modal-container" 
            className="fixed inset-0 bg-black bg-opacity-85 flex justify-center items-center z-50" 
            onClick={handleOutsideClick}
        >
            <div className="relative p-1 rounded-md bg-gradient-to-r from-pink-500 via-red-500 to-red-500 w-full max-w-md">
                <div className="relative bg-[#ffffff] rounded-lg shadow-lg p-5 py-8">
                    
                    {/* Close Icon */}
                    <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>

                    {/* Modal Header */}
                    <img src={logo} alt="Logo" className="w-32 mx-auto mb-6" />
                    <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center valky">Login to Your Account</h2>
                    
                    {/* Error Message */}
                    {error && <div className="bg-red-100 text-red-500 p-2 mb-4 text-sm rounded">{error}</div>}

                    {/* Login Form */}
                    <form onSubmit={handleLogin}>
                        {/* Email Input */}
                        <div className="mb-4">
                            <input 
                                type="email" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email" 
                                className={`w-full p-3 border ${emailError ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-red-400 focus:outline-none`}
                                required
                            />
                            {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>} {/* Email error message */}
                        </div>

                        {/* Password Input */}
                        <div className="mb-4 relative">
                            <input 
                                type={showPassword ? "text" : "password"} // Toggle between text and password
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your password" 
                                className={`w-full p-3 border ${passwordError ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-red-400 focus:outline-none`}
                                required
                            />
                            <button 
                                type="button" 
                                onClick={() => setShowPassword(!showPassword)} 
                                className="absolute right-3 top-3 text-gray-500 hover:text-gray-800 focus:outline-none"
                            >
                                {showPassword ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6">
                                    <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                    <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clipRule="evenodd" />
                                </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6">
              <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
              <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
              <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
          </svg>
                                )}
                            </button>
                            {passwordError && <p className="text-red-500 text-sm mt-1">{passwordError}</p>} {/* Password error message */}
                        </div>

                        <div className="mb-3 text-end underline text-gray-400 hover:text-[#812724] text-sm">
                            <a href="#">Forgot Password?</a>
                        </div>

                        {/* Submit Button */}
                        <button 
                            type="submit" 
                            className="bg-[#AB3430] text-white w-full py-3 rounded-lg hover:bg-[#812724] transition duration-200"
                        >
                            Log in
                        </button>
                    </form>

                    <div className="mt-4 flex justify-center items-center">
                        <span className="text-gray-500">Don’t have an account?</span>
                        <button 
                            className="ml-2 text-red-500 font-medium hover:underline focus:outline-none"
                            onClick={handleRegisterClick}
                        >
                            Sign up
                        </button>
                    </div>
                </div>
            </div>

            {/* Register Modal */}
            {showRegisterModal && <RegisterModal isVisible={showRegisterModal} onClose={handleCloseModal} />}
        </div>
    );
};

export default LoginModal;
