import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

const AddProduct = () => {
  const [product, setProduct] = useState({
    name: '',
    description: '',
    price: '',
    category: '',
    subcategory: '', // New field for subcategory
    sizes: [],
    showProductinSite: false, // New field for showing the product on the site
  });
  const [categories, setCategories] = useState([]); // Store categories from API
  const [subcategories, setSubcategories] = useState([]); // Store subcategories

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get('https://api.pranucollection.com/api/category/getAllCategory'); // Adjust the URL based on your setup
        setCategories(res.data.categories);
      } catch (error) {
        toast.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  
  }, []);

  const fetchSubcategories = async () => {
    try {
      const response = await axios.get('https://api.pranucollection.com/api/subcategory/subcategories');
      console.log('API Response:', response.data); // Debugging line
      const fetchedSubcategories = response.data?.subCategories || [];
      console.log('Fetched Subcategories:', fetchedSubcategories); // Debugging line
      setSubcategories(fetchedSubcategories);
    } catch (err) {
      toast.error('Error fetching subcategories');
    }
  };
  fetchSubcategories();

  
  
  const [imagePreviews, setImagePreviews] = useState([]); // For image preview
  const [compressedImages, setCompressedImages] = useState([]); // For storing compressed images
  const [sizeInput, setSizeInput] = useState({ size: '', colors: [{ color: '', quantity: 0 }] });

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProduct({
      ...product,
      [name]: type === 'checkbox' ? checked : value,
    });

    if (name === 'category') {
      fetchSubcategories(value); // Fetch subcategories when category changes
    }
  };

  // Handle image selection and compress images
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImagePreviews([]); // Reset preview

    files.forEach((file) => {
      // Create a file reader for previewing
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviews((prev) => [...prev, reader.result]);
      };
      reader.readAsDataURL(file);

      // Compress image before storing
      compressImage(file, 1000, (compressedBlob) => {
        setCompressedImages((prev) => [...prev, compressedBlob]);
      });
    });
  };

  // Compress image to reduce size below 1MB
  const compressImage = (file, maxSize, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const scaleFactor = maxSize / Math.max(img.width, img.height);
        canvas.width = img.width * scaleFactor;
        canvas.height = img.height * scaleFactor;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(
          (blob) => {
            callback(blob);
          },
          'image/jpeg',
          0.7 // Adjust compression quality (0.7 works well for reducing size without losing much quality)
        );
      };
    };
  };

  // Handle size input changes
  const handleSizeChange = (e) => {
    setSizeInput({ ...sizeInput, [e.target.name]: e.target.value });
  };

  // Handle color input changes within sizes
  const handleColorChange = (index, e) => {
    const updatedColors = [...sizeInput.colors];
    updatedColors[index][e.target.name] = e.target.value;
    setSizeInput({ ...sizeInput, colors: updatedColors });
  };

  // Add a new color input field
  const addColor = () => {
    setSizeInput({
      ...sizeInput,
      colors: [...sizeInput.colors, { color: '', quantity: 0 }],
    });
  };

  // Add the size and colors to the product
  const addSize = () => {
    setProduct({
      ...product,
      sizes: [...product.sizes, sizeInput],
    });
    // Reset size input
    setSizeInput({ size: '', colors: [{ color: '', quantity: 0 }] });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create form data to send multipart/form-data
    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('description', product.description);
    formData.append('price', product.price);
    formData.append('category', product.category);
    formData.append('subcategory', product.subcategory); // Add subcategory
    formData.append('sizes', JSON.stringify(product.sizes));
    formData.append('showProductinSite', product.showProductinSite); // Add showProductinSite to form data

    // Append all compressed images to the form data
    compressedImages.forEach((image) => {
      formData.append('images', image, `compressed-${Date.now()}.jpg`);
    });

    try {
      // Send the form data to the backend
      const res = await axios.post('https://api.pranucollection.com/api/product/add-product', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Product added successfully!');
      console.log(res.data);
    } catch (error) {
      toast.error('Error adding product:', error);
    }
  };

  return (
    <div className=" mt-5">
      <div className="bg-white ">
        <div className="text-gray-600 font-bold text-3xl mt-5">Add New Product</div>
        <div className="total-category p-2 gap-4 bg-[#309bab33] rounded-md w-fit flex justify-between items-center mt-5">
                    <div>
                        <h2 className="flex gap-2 items-center text-sky-500">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                            </svg>

                            Total Gallery:
                        </h2>
                        <h1 className="text-sky-500 text-[25px] font-bold">{product.length}</h1>
                    </div>
                </div>
        <form onSubmit={handleSubmit} className="space-y-6">

          {/* Name Field */}
          <div className='mt-5'>
            <label className="block text-gray-700 text-sm font-bold mb-2">Product Name</label>
            <input
              type="text"
              name="name"
              value={product.name}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter product name"
              required
            />
          </div>

          {/* Description Field */}
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Description</label>
            <textarea
              name="description"
              value={product.description}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter product description"
              rows="4"
              required
            />
          </div>

          {/* Price and Category */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">Price</label>
              <input
                type="number"
                name="price"
                value={product.price}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter product price"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">Category</label>
              <div className="relative">
  <select
    name="category"
    value={product.category}
    onChange={handleInputChange}
    className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    required
  >
    <option value="">Select a Category</option>
    {categories.map((category) => (
      <option key={category._id} value={category._id}>
        {category.name}
      </option>
    ))}
  </select>
  <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-5 h-5 text-gray-500"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m19.5 8.25-7.5 7.5-7.5-7.5"
      />
    </svg>
  </div>
</div>

            </div>
          </div>

          {/* Subcategory */}
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Subcategory</label>
            <select
              name="subcategory"
              value={product.subcategory}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              required
            >
              <option value="">Select a Subcategory</option>
              {subcategories.length > 0 ? (
                subcategories.map((subcategory) => (
                  <option key={subcategory._id} value={subcategory._id}>
                    {subcategory.name}
                  </option>
                ))
              ) : (
                <option value="">No Subcategories Available</option>
              )}
            </select>
          </div>

          {/* Checkbox for showProductinSite */}
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Show Product on Site</label>
            <input
              type="checkbox"
              name="showProductinSite"
              checked={product.showProductinSite}
              onChange={handleInputChange}
              className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            />
            <span className="ml-2 text-gray-600">Check if you want to display this product on the site</span>
          </div>

          {/* Sizes and Colors Section */}
          <div>
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Sizes and Colors</h3>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">Size</label>
              <input
                type="text"
                name="size"
                value={sizeInput.size}
                onChange={handleSizeChange}
                className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter size (e.g., XL, L, M)"
              />
            </div>

            {sizeInput.colors.map((color, index) => (
              <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">Color</label>
                  <input
                    type="text"
                    name="color"
                    value={color.color}
                    onChange={(e) => handleColorChange(index, e)}
                    className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter color (e.g., Red, Blue)"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">Quantity</label>
                  <input
                    type="number"
                    name="quantity"
                    value={color.quantity}
                    onChange={(e) => handleColorChange(index, e)}
                    className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter quantity"
                  />
                </div>
              </div>
            ))}

            {/* Add Color and Size Buttons */}
            <button
              type="button"
              onClick={addColor}
              className="mt-4 inline-block px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow hover:bg-blue-600 transition"
            >
              Add Another Color
            </button>
            <button
              type="button"
              onClick={addSize}
              className="mt-4 ml-4 inline-block px-6 py-2 bg-green-500 text-white font-semibold rounded-lg shadow hover:bg-green-600 transition"
            >
              Add Size
            </button>

            {/* Show added sizes */}
            <div className="mt-6">
              <h4 className="text-lg font-semibold text-gray-800 mb-3">Added Sizes:</h4>
              {product.sizes.map((size, index) => (
                <div key={index} className="bg-gray-100 p-4 rounded-lg mb-4">
                  <strong className="text-lg">{size.size}</strong>
                  {size.colors.map((color, colorIndex) => (
                    <p key={colorIndex}>{color.color} - Quantity: {color.quantity}</p>
                  ))}
                </div>
              ))}
            </div>
          </div>

          {/* Image Upload Section */}
          <div className="mt-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">Upload Images</label>
            <input
              type="file"
              name="images"
              multiple
              onChange={handleImageChange}
              class=" text-gray-500 w-full font-medium text-base bg-gray-100 file:cursor-pointer cursor-pointer file:border-0 file:py-2.5 file:px-4 file:mr-4 file:bg-gray-800 file:hover:bg-gray-700 file:text-white rounded" 
            />

            {/* Image Preview */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
              {imagePreviews.map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Preview ${index}`}
                  className="w-full h-40 object-cover rounded-lg shadow-md"
                />
              ))}
            </div>
          </div>

          {/* Submit Button */}
          <div className="mt-3">
            <button
              type="submit"
              className=" bg-indigo-600 text-white font-semibold w-fit px-5 py-3 rounded-lg shadow hover:bg-indigo-700 transition"
            >
              Submit Product
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProduct;
