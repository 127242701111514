import React,{useState} from 'react'
import MyRating from '../component/Rating'
import img1 from "../../assets/images/img1.jpg"
import img2 from "../../assets/images/women2.jpg"
import Card from '../component/Card'
import { Link } from 'react-router-dom'
import ImageGallery from './ImageGallery'
const ViewProduct = () => {
    const [count, setCount] = useState(1); // Initial count is set to 2

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };
  return (
    <>
      <h1 className='text-center p-5 bg-[#E3DCD7] valky font-bold text-gray-600'>
   View Product
   </h1>
    <div className='container mx-10 lg:mx-auto py-10 '>
    <div className='grid grid-cols-1 lg:grid-cols-2 gap-10'>

<ImageGallery/>
<div className='p-5 pr-5 lg:pr-[150px]'>
    <div className='font-medium'>KURTHA</div>
        <h1>Lorem ipsum dolor sit amet</h1>
        <MyRating/>
        <h2 className='font-medium'>Rs 1000</h2>
        <p className='font-thin'>The garments labelled as Committed are products that have been produced using sustainable fibres or processes, reducing their environmental impact.</p>
    <hr/>
    
    <div className='text-green-400'>In Stock</div>
    <div className=''>
        Quantity:
    </div>
    <div className='grid grid-cols-3 gap-5 mt-5'>
    <div className='flex items-center justify-center space-x-4'>
      <button onClick={decrement} className='bg-gray-100 p-4 py-2 w-12'>-</button>
      <span className='text-xl'>{count}</span>
      <button onClick={increment} className='bg-gray-100 p-4 py-2 w-12'>+</button>
    </div>
    <button className='border px-5 py-3 w-full col-span-2'>Add to cart</button>
    </div>
    <button className='bg-[#AB3430] text-white px-5 py-3 w-full col-span-2 mt-5' >Buy Now</button>
    <div className='my-5'>
    <hr/>
    </div>
 
    <h2 className='font-medium '>Get it Now</h2>
    <div className='flex gap-2 pt-2'>
        <img src='https://anvogue.presslayouts.com/wp-content/uploads/2024/05/product-service-icon1.svg' alt="shiping"/>
        <div>
            <h4>Free shipping
            </h4>
            <h5 className='text-gray-400'> Free shipping on orders over Rs105.
            </h5>
        </div>

    </div>
    <div className='flex gap-2 pt-2'>
        <img src="https://anvogue.presslayouts.com/wp-content/uploads/2024/05/product-service-icon2.svg" alt="shiping"/>
        <div>
            <h4>30 - Day Returns

            </h4>
            <h5 className='text-gray-400'> Not impressed? Get a refund. You have 30 days to break our hearts.

            </h5>
        </div>

    </div>
    <div className='flex gap-2 pt-2'>
        <img src='https://anvogue.presslayouts.com/wp-content/uploads/2024/05/product-service-icon3.svg' alt="shiping"/>
        <div>
            <h4>Dedicated Support

            </h4>
            <h5 className='text-gray-400'>Support from 8:30 AM to 10:00 PM everyday
            </h5>
        </div>

    </div>

    </div>
    </div>
    <hr/>
    {/* <div className='grid grid-cols-2 gap-10 mx-10 pt-10 pb-5'>
    <div className=''>
    <h2>Description
    </h2>
    <p>The garments labelled as Committed are products that have been produced using sustainable fibres or processes, reducing their environmental impact.</p>
    </div>
    <div className=''>
    <h2>About Product
    </h2>
    <p>The garments labelled as Committed are products that have been produced using sustainable fibres or processes, reducing their environmental impact.</p>
    </div>
        </div> */}
        {/* <hr/> */}
        <div className='BestSeller  px-10 bg-white pb-10 pt-10'>
                    <div className='text-[#767676] text-3xl font-medium pb-5'>New Arrivals</div>
                    <div className='grid grid-cols-2 lg:grid-cols-4 gap-10  pb-10'>
                        <Link to="/viewProduct">
                        <Card />
                        </Link>
                     
                        <Card />
                        <Card />
                        <Card />

                    </div>

                </div>
   
    </div>

    </>
  )
}

export default ViewProduct