import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { addCategoryApi, getCategoryApi } from '../../../Apis/Api'; // Import the API functions
import TablePagination from '@mui/material/TablePagination';


const AddCategory = () => {
  const [categoryName, setCategoryName] = useState(''); // Input for new category
  const [categories, setCategories] = useState([]); // All categories
  const [loading, setLoading] = useState(true); // Loading state
  const [searchQuery, setSearchQuery] = useState(''); // Search input
  const [page, setPage] = useState(0); // Pagination: current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Pagination: rows per page

  // Fetch categories from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategoryApi();
        setCategories(response.data.categories); // Assuming categories are in the response
        setLoading(false);
      } catch (error) {
        toast.error('Error fetching categories!');
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // Filtered categories based on search query
  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle adding new category
  const handleAddCategory = async (e) => {
    e.preventDefault();

    try {
      const response = await addCategoryApi({ name: categoryName });
      toast.success(response.data.message);
      setCategoryName(''); // Reset input after success

      // Fetch categories again after adding
      const updatedCategories = await getCategoryApi();
      setCategories(updatedCategories.data.categories);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error adding category');
    }
  };

  // Pagination: handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Pagination: handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to the first when rows per page change
  };

  // If still loading, show a loading message
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div className="mx-auto p-5 mt-10">
        <div className="text-gray-600 font-bold text-3xl">Add Category</div>

        {/* Display total categories */}
        <div className="total-category p-2 gap-4 bg-[#ab953033] rounded-md w-fit flex justify-between items-center mt-5">
          <div>
            <h2 className="flex gap-2 items-center text-orange-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
</svg>

              Total Categories:
            </h2>
            <h1 className="text-orange-400 text-[25px] font-bold">{categories.length}</h1>
          </div>
        </div>

        {/* Add Category Form */}
        <form onSubmit={handleAddCategory}>
          <div className="mb-4 pt-3">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="categoryName"
            >
              Category Name
            </label>
            <input
              type="text"
              name="categoryName"
              placeholder="Enter category name"
              id="categoryName"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              className="shadow appearance-none border rounded w-[50%] py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <button
            type="submit"
            className="bg-[#AB3430] hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Add Category
          </button>
        </form>

        {/* Categories Table */}
        <div className="py-5">
          <hr />
        </div>
        <div className="">
          <h2 className="text-xl font-bold">All Categories</h2>

          {/* Search Form */}
          <form className="py-2">
            <div className="relative">
              <input
                type="search"
                id="default-search"
                className="block w-[50%] p-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search Categories..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </form>

          {/* Categories Table */}
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg rounded-xl mt-3">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">SN</th>
                  <th scope="col" className="px-6 py-3">Category Name</th>
                </tr>
              </thead>
              <tbody>
                {filteredCategories
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Slice categories for pagination
                  .map((category, index) => (
                    <tr
                      key={category._id}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="px-6 py-4">{index + 1 + page * rowsPerPage}</td>
                      <td className="px-6 py-4">{category.name}</td>
                    </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination */}
            <TablePagination
              component="div"
              count={filteredCategories.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage="Rows per page"
            />
          </div>
        </div>
      </div>
    
    </>
  );
};

export default AddCategory;
