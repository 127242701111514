import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const AddGallery = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [galleries, setGalleries] = useState([]); // Ensure it's initialized as an empty array

    // Fetch galleries when the component mounts
    useEffect(() => {
        const fetchGalleries = async () => {
            try {
                const response = await axios.get('https://api.pranucollection.com/api/gallery/getAllGallery');
                if (response.data.success) {
                    setGalleries(response.data.gallery || []); // Correct property name is 'gallery'
                } else {
                    toast.error('Failed to fetch galleries');
                }
            } catch (error) {
                toast.error('An error occurred while fetching galleries.');
            }
        };

        fetchGalleries();
    }, []); // Empty dependency array to run only once

    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        setImage(selectedFile);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title || !description || !image) {
            toast.error('Please provide title, description, and an image.');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('image', image);

        try {
            const response = await axios.post('https://api.pranucollection.com/api/gallery/addGallery', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                toast.success('Gallery created successfully!');
                setTitle('');
                setDescription('');
                setImage(null);

                // Fetch galleries again to update the list
                const fetchGalleries = async () => {
                    const response = await axios.get('https://api.pranucollection.com/api/gallery/getAllGallery');
                    if (response.data.success) {
                        setGalleries(response.data.gallery || []); // Correct property name is 'gallery'
                    }
                };
                fetchGalleries();
            } else {
                toast.error(response.data.message || 'Failed to create gallery');
            }
        } catch (error) {
            toast.error('An error occurred while creating the gallery.');
        }
    };

    return (
        <div className="px-2">
            <div className='  mx-auto'>
                <div className="text-gray-600 font-bold text-3xl mt-5"> Create New Gallery</div>
                {/* Display total categories */}
                <div className="total-category p-2 gap-4 bg-[#38ab3033] rounded-md w-fit flex justify-between items-center mt-5">
                    <div>
                        <h2 className="flex gap-2 items-center text-[#30ab36]">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                            </svg>

                            Total Gallery:
                        </h2>
                        <h1 className="text-[#30ab36] text-[25px] font-bold">{galleries.length}</h1>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='mt-5'>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Title</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter gallery title"
                            className="shadow appearance-none border rounded lg:w-[50%] py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Description</label>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter gallery description"
                            className="shadow appearance-none border rounded lg:w-[50%] py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Upload Image</label>
                        <input type="file" onChange={handleImageChange} class=" text-gray-500 lg:w-[50%] font-medium text-base bg-gray-100 file:cursor-pointer cursor-pointer file:border-0 file:py-2.5 file:px-4 file:mr-4 file:bg-gray-800 file:hover:bg-gray-700 file:text-white rounded" />
                    </div>
                    <button
                        className="bg-[#AB3430] hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-3"
                        type="submit">Create Gallery</button>
                </form>
            </div>





        </div>
    );
};

export default AddGallery;
