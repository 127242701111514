import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAllCategoryApi } from '../../../Apis/Api';
import toast from 'react-hot-toast'; // Already imported

const AddSubCategory = () => {
  const [name, setName] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]); // To store the subcategories
  const [searchQuery, setSearchQuery] = useState(''); // For search functionality
  const [filteredSubcategories, setFilteredSubcategories] = useState([]); // For filtered subcategories based on search

  // Fetch all categories and subcategories when the component mounts
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getAllCategoryApi(); // Fetch categories from your API
        setCategories(response.data?.categories || []); // Ensure categories is defined
      } catch (err) {
        toast.error('Error fetching categories');
      }
    };

    const fetchSubcategories = async () => {
      try {
        const response = await axios.get('https://api.pranucollection.com/api/subcategory/subcategories');
        console.log('API Response:', response.data); // Debugging line
        const fetchedSubcategories = response.data?.subCategories || [];
        console.log('Fetched Subcategories:', fetchedSubcategories); // Debugging line
        setSubcategories(fetchedSubcategories);
        setFilteredSubcategories(fetchedSubcategories);
      } catch (err) {
        toast.error('Error fetching subcategories');
      }
    };

    fetchCategories();
    fetchSubcategories();
  }, []);

  // Handle form submission for adding a new subcategory
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.pranucollection.com/api/subcategory/addSubCategory', {
        name,
        categoryId,
      });

      if (response.data.success) {
        toast.success(response.data.message); // Show success message
        setName('');
        setCategoryId('');
        // Fetch the updated subcategories list after adding
        const updatedSubcategories = await axios.get('https://api.pranucollection.com/api/subcategory/subcategories');
        const subcategoriesList = updatedSubcategories.data?.subcategories || [];
        setSubcategories(subcategoriesList);
        setFilteredSubcategories(subcategoriesList); // Reset the filtered list
      }
    } catch (err) {
      toast.error(err.response?.data?.message || 'Something went wrong'); // Show error message
    }
  };

  // Handle the search query for subcategories
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = subcategories.filter((subcategory) =>
      subcategory.name.toLowerCase().includes(query)
    );
    setFilteredSubcategories(filtered);
  };

  return (
    <div className="mx-auto p-5 mt-10">
      <h2 className="text-gray-600  text-3xl font-bold mb-4">Add Subcategory</h2>
      <div className="total-category p-2 gap-4 bg-[#ab953033] rounded-md w-fit flex justify-between items-center mt-5">
          <div>
            <h2 className="flex gap-2 items-center text-orange-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
</svg>

              Total Categories:
            </h2>
            <h1 className="text-orange-400 text-[25px] font-bold">{subcategories.length}</h1>
          </div>
        </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2 font-medium">Subcategory Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="shadow appearance-none border rounded w-[50%] py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium">Select Category</label>
          <select
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
            className="shadow appearance-none border rounded w-[50%] py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Select a Category</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <button type="submit"             className="bg-[#AB3430] hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Add Subcategory
        </button>
      </form>

      <div className="mt-8">
        <h3 className="text-lg font-semibold mb-4">Subcategories List</h3>

        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search subcategories..."
          className="block w-[50%] p-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
          />

        <table className="w-full text-sm text-left text-gray-500 border mt-3">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">SN</th>
              <th scope="col" className="px-6 py-3">Subcategory Name</th>
              <th scope="col" className="px-6 py-3">Category</th>
            </tr>
          </thead>
          <tbody>
            {filteredSubcategories?.length > 0 ? (
              filteredSubcategories.map((subcategory, index) => {
                // Check if subcategory.category is an object (populated) or a string (ID)
                const categoryId = typeof subcategory.category === 'object' ? subcategory.category._id : subcategory.category;

                // Find the category name by matching the categoryId with the fetched categories
                const categoryName = categories.find(
                  (category) => category._id === categoryId
                )?.name || 'Unknown';

                return (
                  <tr key={subcategory._id} className="bg-white border-b hover:bg-gray-50">
                    <td className="px-6 py-4">{index + 1}</td>
                    <td className="px-6 py-4">{subcategory.name}</td>
                    <td className="px-6 py-4">{categoryName}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="3" className="px-6 py-4 text-center">No subcategories found</td>
              </tr>
            )}
          </tbody>




        </table>
      </div>
    </div>
  );
};

export default AddSubCategory;
