import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import TablePagination from '@mui/material/TablePagination'; // Ensure you have installed MUI

const ViewGallery = () => {
    const [galleries, setGalleries] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredGalleries, setFilteredGalleries] = useState([]); // For search results
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Fetch galleries when the component mounts
    useEffect(() => {
        const fetchGalleries = async () => {
            try {
                const response = await axios.get('https://api.pranucollection.com/api/gallery/getAllGallery');
                if (response.data.success) {
                    setGalleries(response.data.gallery || []);
                    setFilteredGalleries(response.data.gallery || []);
                } else {
                    toast.error('Failed to fetch galleries');
                }
            } catch (error) {
                toast.error('An error occurred while fetching galleries.');
            }
        };

        fetchGalleries();
    }, []);

    // Handle search
    useEffect(() => {
        const results = galleries.filter((gallery) =>
            gallery.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            gallery.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredGalleries(results);
    }, [searchTerm, galleries]);

    // Pagination handlers
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className='p-2'>
            <h2 className="text-xl font-bold">All Gallery</h2>
            <form className="py-2">
                <div className="relative">
                    <input
                        type="search"
                        id="default-search"
                        className="block w-[50%] p-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Search Gallery..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </form>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg rounded-xl mt-3">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">SN</th>
                            <th scope="col" className="px-6 py-3">Title Name</th>
                            <th scope="col" className="px-6 py-3">Description</th>
                            <th scope="col" className="px-6 py-3">Image</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredGalleries
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((gallery, index) => (
                                <tr key={gallery._id}>
                                    <td className="px-6 py-4">{index + 1 + page * rowsPerPage}</td>
                                    <td className="px-6 py-4">{gallery.title}</td>
                                    <td className="px-6 py-4">{gallery.description}</td>
                                    <td className="px-6 py-4">
                                        <img
                                            src={gallery.image} // Ensure the image is accessible
                                            alt={gallery.title}
                                            className="w-20 h-20 object-cover"
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <TablePagination
                component="div"
                count={filteredGalleries.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage="Rows per page"
            />
        </div>
    );
};

export default ViewGallery;
