import axios from "axios";

const Api = axios.create({
  // baseURL: "http://localhost:5000/",
  baseURL: "https://api.pranucollection.com",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});
const config = {
  headers: {
    authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};
export const addCategoryApi = (categoryData) => Api.post('/api/category/add-category', categoryData);
export const getAllProductApi = () => Api.get('/api/product/getAllProducts');
export const loginApi = (loginData) => Api.post('/api/login', loginData);
export const registerApi = (registerData) => Api.post('api/register', registerData);
export const logoutUserApi =()=>Api.post("api/logout");
export const getCategoryApi = () => Api.get('/api/category/getAllCategory');
export const getAllCategoryApi = () => Api.get('/api/category/getAllCategory');
export const addSubCategoryApi = () => Api.get('/api//subcategory/addsubcategory');
export const getAllSubCategoryApi = () => Api.get('/api/subcategory/subcategory/:id');
export const createGalleryApi = (galleryData) => Api.post('', galleryData);
export const getAllGalleryApi = () => Api.get('/api/gallery/getAllGallery');


