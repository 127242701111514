import React,{useState} from 'react'
import AddGallery from './AddGallery';
import ViewGallery from './ViewGallery';

const Gallerys = () => {
  const [view, setView] = useState('add'); // Set default view to 'add'
  return (
    <>
    <div className="p-4">
    <div className="pt-10 mt-3">
      <div className="flex justify-start gap-4">
        <button
          className={`btn p-2 hover:text-[#AB3430] transition-all duration-300 ${
            view === 'add'
              ? 'text-[#AB3430] border-b border-[#AB3430]'
              : 'border-b border-transparent'
          }`}
          onClick={() => setView('add')}
        >
          Add Gallery
        </button>

        <button
          className={`btn p-2 hover:text-[#AB3430] transition-all duration-300 ${
            view === 'view'
              ? 'text-[#AB3430] border-b border-[#AB3430]'
              : 'border-b border-transparent'
          }`}
          onClick={() => setView('view')}
        >
          View Product
        </button>
      </div>

      {view === 'view' && (
        <ViewGallery/>
      
      )}

      {view === 'add' && (<AddGallery/>)}
    </div>
  </div>
</>
  )
}

export default Gallerys
